/* =======================================================================
Core
========================================================================== */
/* =======================================================================
Responsive
========================================================================== */
/* =======================================================================
Font-face
========================================================================== */
/* =======================================================================
Variables
========================================================================== */
/* =======================================================================
Helpers
========================================================================== */
/* line 5, public/wp-content/themes/groupeefficace/assets/src/scss/core/_helpers.scss */
.h-centered {
  text-align: center;
}

/* line 9, public/wp-content/themes/groupeefficace/assets/src/scss/core/_helpers.scss */
.h-clearfix:before,
.h-clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

/* line 15, public/wp-content/themes/groupeefficace/assets/src/scss/core/_helpers.scss */
.h-clearfix:after {
  clear: both;
}

/* line 19, public/wp-content/themes/groupeefficace/assets/src/scss/core/_helpers.scss */
.h-clearfix {
  *zoom: 1;
}

/* line 23, public/wp-content/themes/groupeefficace/assets/src/scss/core/_helpers.scss */
.h-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* line 32, public/wp-content/themes/groupeefficace/assets/src/scss/core/_helpers.scss */
.h-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* line 43, public/wp-content/themes/groupeefficace/assets/src/scss/core/_helpers.scss */
.h-embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}

/* line 51, public/wp-content/themes/groupeefficace/assets/src/scss/core/_helpers.scss */
.h-embed-responsive iframe,
.h-embed-responsive embed,
.h-embed-responsive object,
.h-embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
}

/* line 64, public/wp-content/themes/groupeefficace/assets/src/scss/core/_helpers.scss */
.h-embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

/* line 68, public/wp-content/themes/groupeefficace/assets/src/scss/core/_helpers.scss */
.h-embed-responsive-4by3 {
  padding-bottom: 75%;
}

/* =======================================================================
Global
========================================================================== */
/* line 1, public/wp-content/themes/groupeefficace/assets/src/scss/global/_base.scss */
html,
button,
input,
select,
textarea {
  color: #333;
  font-family: Roboto, Arial, Verdana, sans-serif;
}

/* line 10, public/wp-content/themes/groupeefficace/assets/src/scss/global/_base.scss */
body,
html {
  padding: 0;
  margin: 0;
  font-size: 18px;
  line-height: 1.4;
}

/* line 18, public/wp-content/themes/groupeefficace/assets/src/scss/global/_base.scss */
* {
  box-sizing: border-box;
}

/* line 22, public/wp-content/themes/groupeefficace/assets/src/scss/global/_base.scss */
img {
  vertical-align: middle;
  max-width: 100%;
}

/* line 24, public/wp-content/themes/groupeefficace/assets/src/scss/global/_base.scss */
h2 {
  line-height: 1.2em;
}

/* line 28, public/wp-content/themes/groupeefficace/assets/src/scss/global/_base.scss */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/* line 37, public/wp-content/themes/groupeefficace/assets/src/scss/global/_base.scss */
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/* line 42, public/wp-content/themes/groupeefficace/assets/src/scss/global/_base.scss */
textarea {
  resize: vertical;
}

/* line 47, public/wp-content/themes/groupeefficace/assets/src/scss/global/_base.scss */
::-moz-selection {
  background: black;
  color: white;
  text-shadow: none;
}

/* line 53, public/wp-content/themes/groupeefficace/assets/src/scss/global/_base.scss */
::selection {
  background: black;
  color: white;
  text-shadow: none;
}

/* line 60, public/wp-content/themes/groupeefficace/assets/src/scss/global/_base.scss */
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: white;
  opacity: 0.5;
}

/* line 64, public/wp-content/themes/groupeefficace/assets/src/scss/global/_base.scss */
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: white;
  opacity: 0.5;
}

/* line 68, public/wp-content/themes/groupeefficace/assets/src/scss/global/_base.scss */
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: white;
  opacity: 0.5;
}

/* line 72, public/wp-content/themes/groupeefficace/assets/src/scss/global/_base.scss */
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: white;
  opacity: 0.5;
}

/* =======================================================================
Forms
========================================================================== */
/* line 5, public/wp-content/themes/groupeefficace/assets/src/scss/global/_forms.scss */
input, textarea {
  height: 40px;
  border-radius: 100px;
  border: 1px solid white;
  background: transparent;
  color: white;
  padding: 1.2em 1.4em;
  margin: 0.5em 0;
  font-size: 1rem;
  outline: none;
  width: 100%;
  max-width: 800px;
}

/* line 19, public/wp-content/themes/groupeefficace/assets/src/scss/global/_forms.scss */
textarea {
  height: 200px;
  resize: vertical;
  border-radius: 24px;
}

/* line 25, public/wp-content/themes/groupeefficace/assets/src/scss/global/_forms.scss */
span.wpcf7-not-valid-tip {
  margin-bottom: 0px;
  color: #f78b38;
}

/* line 30, public/wp-content/themes/groupeefficace/assets/src/scss/global/_forms.scss */
div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
  color: #f78b38;
  border: none;
  padding: 0;
}

/* line 36, public/wp-content/themes/groupeefficace/assets/src/scss/global/_forms.scss */
div.wpcf7-mail-sent-ok {
  color: white;
  border: none;
  padding: 0;
}

/* =======================================================================
Layout
========================================================================== */
/* line 5, public/wp-content/themes/groupeefficace/assets/src/scss/global/_layout.scss */
.l-container {
  width: 100%;
  max-width: 1660px;
  margin: 0 auto;
}

/* line 11, public/wp-content/themes/groupeefficace/assets/src/scss/global/_layout.scss */
.l-row {
  clear: both;
  display: block;
}

/* line 16, public/wp-content/themes/groupeefficace/assets/src/scss/global/_layout.scss */
.l-row--flex {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 992px) {
  /* line 16, public/wp-content/themes/groupeefficace/assets/src/scss/global/_layout.scss */
  .l-row--flex {
    flex-direction: column;
  }
}

/* line 25, public/wp-content/themes/groupeefficace/assets/src/scss/global/_layout.scss */
.l-8 {
  width: 66.6666666667%;
}

@media (max-width: 992px) {
  /* line 25, public/wp-content/themes/groupeefficace/assets/src/scss/global/_layout.scss */
  .l-8 {
    width: 100%;
  }
}

/* line 33, public/wp-content/themes/groupeefficace/assets/src/scss/global/_layout.scss */
.l-7 {
  width: 58.3333333333%;
}

@media (max-width: 992px) {
  /* line 33, public/wp-content/themes/groupeefficace/assets/src/scss/global/_layout.scss */
  .l-7 {
    width: 100%;
  }
}

/* line 41, public/wp-content/themes/groupeefficace/assets/src/scss/global/_layout.scss */
.l-6 {
  width: 50%;
  margin: 16px;
}

/* line 45, public/wp-content/themes/groupeefficace/assets/src/scss/global/_layout.scss */
.l-6:nth-of-type(2n + 1) {
  margin-right: 8px;
}

@media (max-width: 992px) {
  /* line 45, public/wp-content/themes/groupeefficace/assets/src/scss/global/_layout.scss */
  .l-6:nth-of-type(2n + 1) {
    margin-right: 16px;
  }
}

/* line 52, public/wp-content/themes/groupeefficace/assets/src/scss/global/_layout.scss */
.l-6:nth-of-type(2n) {
  margin-left: 8px;
}

@media (max-width: 992px) {
  /* line 52, public/wp-content/themes/groupeefficace/assets/src/scss/global/_layout.scss */
  .l-6:nth-of-type(2n) {
    margin-top: 0;
    margin-left: 16px;
  }
}

@media (max-width: 992px) {
  /* line 41, public/wp-content/themes/groupeefficace/assets/src/scss/global/_layout.scss */
  .l-6 {
    width: calc(100% - 32px);
  }
}

/* line 65, public/wp-content/themes/groupeefficace/assets/src/scss/global/_layout.scss */
.l-4 {
  width: 25%;
}

@media (max-width: 992px) {
  /* line 65, public/wp-content/themes/groupeefficace/assets/src/scss/global/_layout.scss */
  .l-4 {
    width: 100%;
  }
}

/* line 73, public/wp-content/themes/groupeefficace/assets/src/scss/global/_layout.scss */
.l-3 {
  width: 33.3333333333%;
}

@media (max-width: 992px) {
  /* line 73, public/wp-content/themes/groupeefficace/assets/src/scss/global/_layout.scss */
  .l-3 {
    width: 100%;
  }
}

/* =======================================================================
Navigation
========================================================================== */
@media (max-width: 992px) {
  /* line 1, public/wp-content/themes/groupeefficace/assets/src/scss/global/_helpers.scss */
  .h-hide-tablet {
    display: none;
  }
}

/* line 7, public/wp-content/themes/groupeefficace/assets/src/scss/global/_helpers.scss */
.h-show-tablet {
  display: none;
}

@media (max-width: 992px) {
  /* line 7, public/wp-content/themes/groupeefficace/assets/src/scss/global/_helpers.scss */
  .h-show-tablet {
    display: inline-block;
  }
}

/* line 14, public/wp-content/themes/groupeefficace/assets/src/scss/global/_helpers.scss */
.h-margin-top-15 {
  margin-top: 15px !important;
}

/* =======================================================================
Components
========================================================================== */
/* =======================================================================
c-banner
========================================================================== */
/* line 5, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-banner.scss */
.c-banner, .c-banner--category {
  height: 700px;
  margin: 0px 16px;
  background-size: cover;
  background-position: 50% 30%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  /* line 5, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-banner.scss */
  .c-banner, .c-banner--category {
    height: auto;
    padding: 30px 0 60px 0;
  }
}

/* line 21, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-banner.scss */
.c-banner--category {
  height: auto;
  padding: 100px 0 150px 0;
  margin-bottom: 16px;
}

/* line 26, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-banner.scss */
.c-banner--category:before {
  background: linear-gradient(-45deg, rgba(30, 62, 103, 0.3), rgba(30, 62, 103, 0.6));
}

@media (max-width: 767px) {
  /* line 21, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-banner.scss */
  .c-banner--category {
    padding: 50px 0 100px 0;
  }
}

/* line 38, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-banner.scss */
.c-banner:before, .c-banner--category:before {
  content: "";
  height: 100%;
  width: 100%;
  background: linear-gradient(-45deg, rgba(30, 62, 103, 0.5), rgba(30, 62, 103, 0.8));
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

/* line 53, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-banner.scss */
.c-banner__content {
  position: relative;
  z-index: 2;
  color: white;
  width: 100%;
  padding: 3rem;
}

@media (max-width: 767px) {
  /* line 53, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-banner.scss */
  .c-banner__content {
    padding: 1rem 30px;
  }
}

/* line 65, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-banner.scss */
.c-banner__content h2 {
  font-size: 3rem;
  margin-bottom: 0px;
}

@media (max-width: 992px) {
  /* line 65, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-banner.scss */
  .c-banner__content h2 {
    font-size: 2.4rem;
  }
}

@media (max-width: 767px) {
  /* line 65, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-banner.scss */
  .c-banner__content h2 {
    font-size: 1.8rem;
  }
}

/* line 78, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-banner.scss */
.c-banner__content p {
  margin-bottom: 2em;
  display: block;
  max-width: 900px;
}

/* line 84, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-banner.scss */
.c-banner__content a:not(.c-button):not(.c-button--blue) {
  color: #f78b38;
  text-decoration: none;
}

/* line 90, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-banner.scss */
.c-banner__title {
  text-align: center;
}

/* line 94, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-banner.scss */
.c-banner__logo {
  position: absolute;
  height: 430px;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
}

@media (max-width: 992px) {
  /* line 94, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-banner.scss */
  .c-banner__logo {
    height: 200px;
    opacity: 0.5;
    right: 16px;
  }
}

/* line 1, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-header.scss */
.c-header {
  box-shadow: 0 0 10px rgba(19, 19, 19, 0.3);
  background: #131313;
  margin: 16px;
}

/* line 7, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-header.scss */
.c-header__logo {
  height: 120px;
  padding: 1.4em 1em;
}

/* line 12, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-header.scss */
.c-header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

/* line 19, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-header.scss */
.c-header__nav {
  display: flex;
  flex-direction: row;
  list-style: none;
}

/* line 25, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-header.scss */
.c-header__link {
  padding: .6em 1em;
  color: white;
  text-decoration: none;
  font-size: 1rem;
  position: relative;
}

/* line 31, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-header.scss */
.c-header__link:before {
  content: "";
  height: 1px;
  width: 0%;
  background: white;
  position: absolute;
  bottom: 0;
  left: 10px;
  transition: all 300ms ease;
}

/* line 42, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-header.scss */
.c-header__link:hover:before {
  width: calc(100% - 20px);
}

/* line 48, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-header.scss */
.c-header__hamburger {
  height: 40px;
  display: block;
}

/* line 1, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-footer.scss */
.c-footer {
  background: #131313;
  margin: 0 16px 16px 16px;
}

/* line 5, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-footer.scss */
.c-footer .l-3, .c-footer .l-7, .c-footer .l-2 {
  padding: 48px 32px;
}

@media (max-width: 992px) {
  /* line 5, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-footer.scss */
  .c-footer .l-3, .c-footer .l-7, .c-footer .l-2 {
    padding: 16px;
  }
}

/* line 14, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-footer.scss */
.c-footer__logo {
  height: 90px;
  padding: 0;
}

@media (max-width: 992px) {
  /* line 14, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-footer.scss */
  .c-footer__logo {
    height: 60px;
    margin-top: 16px;
  }
}

/* line 24, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-footer.scss */
.c-footer__container {
  display: flex;
  padding: 0 20px;
}

@media (max-width: 992px) {
  /* line 24, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-footer.scss */
  .c-footer__container {
    flex-direction: column;
  }
}

/* line 33, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-footer.scss */
.c-footer__bio {
  color: white;
  display: block;
  max-width: 600px;
  margin: 0.4em 0;
}

/* line 40, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-footer.scss */
.c-footer__nav {
  list-style: none;
  margin: 0px;
}

@media (max-width: 992px) {
  /* line 40, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-footer.scss */
  .c-footer__nav {
    padding: 0;
  }
}

/* line 48, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-footer.scss */
.c-footer__nav a:not(.c-button):not(.c-button--blue) {
  color: white;
  opacity: 1;
  padding: 0.3em 0;
  display: inline-block;
  text-decoration: none;
  position: relative;
}

/* line 55, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-footer.scss */
.c-footer__nav a:not(.c-button):not(.c-button--blue):before {
  content: "";
  height: 1px;
  width: 0%;
  background: #f78b38;
  position: absolute;
  bottom: 0;
  left: 0px;
  transition: all 300ms ease;
}

/* line 66, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-footer.scss */
.c-footer__nav a:not(.c-button):not(.c-button--blue):hover:before {
  width: 100%;
}

/* line 72, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-footer.scss */
.c-footer__nav .c-button--blue {
  display: inline-block;
  margin-bottom: 1em;
  font-size: 16px;
}

/* line 1, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-copyright.scss */
.c-copyright {
  background-color: #f78b38;
  margin: 0 16px 16px;
}

/* line 5, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-copyright.scss */
.c-copyright p {
  margin: 0;
  padding: 1em;
  color: white;
  text-align: center;
  font-size: 0.8rem;
}

/* line 1, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-button.scss */
.c-button, .c-button--blue {
  cursor: pointer;
  background: #f78b38;
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.6em 2em;
  border-radius: 100px;
  border-top-right-radius: 0px;
  outline: none;
  transition: all 200ms ease;
}

/* line 12, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-button.scss */
.c-button:hover, .c-button--blue:hover {
  background: #f26f0a;
}

/* line 17, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-button.scss */
.c-button--blue {
  background: #2c5b96;
}

/* line 20, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-button.scss */
.c-button--blue:hover {
  background: #20436f;
}

/* =======================================================================
c-category-box
========================================================================== */
/* line 5, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-category-box.scss */
.c-category-box {
  height: 500px;
  background-size: cover;
  background-position: 50% 30%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 992px) {
  /* line 5, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-category-box.scss */
  .c-category-box {
    height: 400px;
  }
}

/* line 20, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-category-box.scss */
.c-category-box:before {
  content: "";
  height: 100%;
  width: 100%;
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

/* line 35, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-category-box.scss */
.c-category-box__content {
  position: relative;
  z-index: 2;
  color: white;
  width: 100%;
  padding: 3rem;
}

@media (max-width: 767px) {
  /* line 35, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-category-box.scss */
  .c-category-box__content {
    padding: 1rem 30px;
  }
}

/* line 47, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-category-box.scss */
.c-category-box__content h2 {
  font-size: 3rem;
  margin-top: 0px;
  margin-bottom: 0.5em;
}

@media (max-width: 992px) {
  /* line 47, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-category-box.scss */
  .c-category-box__content h2 {
    font-size: 2.4rem;
  }
}

@media (max-width: 767px) {
  /* line 47, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-category-box.scss */
  .c-category-box__content h2 {
    font-size: 1.8rem;
  }
}

/* line 61, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-category-box.scss */
.c-category-box__content p {
  margin-bottom: 0;
}

/* line 66, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-category-box.scss */
.c-category-box__title {
  text-align: center;
}

/* line 1, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-services-list.scss */
.c-services-list {
  margin: -16px 16px 16px 16px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  padding: 3rem 16px 3rem 16px;
  background: rgba(44, 91, 150, 0.1);
}

@media (max-width: 992px) {
  /* line 1, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-services-list.scss */
  .c-services-list {
    padding: 16px;
  }
}

@media (max-width: 767px) {
  /* line 1, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-services-list.scss */
  .c-services-list {
    padding: 0;
    background: transparent;
  }
}

/* line 20, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-services-list.scss */
.c-services-list__item {
  width: 400px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  background-color: white;
  padding: 1.6em;
  margin: 0 10px 1em 10px;
  color: #2c5b96;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  transform: scale(1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  background-size: cover;
  transition: all 350ms ease;
}

@media (max-width: 992px) {
  /* line 20, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-services-list.scss */
  .c-services-list__item {
    width: calc(50% - 20px);
    min-height: 200px;
  }
}

@media (max-width: 767px) {
  /* line 20, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-services-list.scss */
  .c-services-list__item {
    width: 100%;
    margin: 15px 0 0 0;
    min-height: 200px;
  }
}

/* line 53, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-services-list.scss */
.c-services-list__item h3 {
  font-size: 1.4rem;
  position: relative;
  color: white;
  z-index: 2;
}

/* line 60, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-services-list.scss */
.c-services-list__item p {
  margin-top: 0;
  font-size: 1rem;
  position: relative;
  color: white;
  z-index: 2;
}

/* line 68, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-services-list.scss */
.c-services-list__item span, .c-services-list__item button {
  position: relative;
  z-index: 2;
  border: none;
}

/* line 74, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-services-list.scss */
.c-services-list__item:before {
  content: url(../images/svg/logo-icon.svg);
  opacity: 0.1;
  position: absolute;
  z-index: 1;
  top: -120px;
  left: 50%;
  transform: translateX(-50%) scale(1.4);
}

/* line 84, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-services-list.scss */
.c-services-list__item:hover {
  z-index: 2;
  transform: scale(1.03);
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.1);
}

/* line 91, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-services-list.scss */
.c-services-list__item:after {
  content: "";
  height: 100%;
  width: 100%;
  background: linear-gradient(-45deg, rgba(30, 62, 103, 0.7), rgba(30, 62, 103, 0.9));
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

/* line 1, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-breadcrumb.scss */
.c-breadcrumb {
  margin: 16px;
}

/* line 4, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-breadcrumb.scss */
.c-breadcrumb li {
  display: inline-block;
  padding: 0;
}

@media (max-width: 767px) {
  /* line 9, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-breadcrumb.scss */
  .c-breadcrumb ul {
    padding: 0;
  }
}

/* line 15, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-breadcrumb.scss */
.c-breadcrumb li:not(:first-of-type) a {
  padding-left: 30px;
  position: relative;
}

/* line 18, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-breadcrumb.scss */
.c-breadcrumb li:not(:first-of-type) a:before {
  content: "-";
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 0px 0.5em;
}

/* line 28, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-breadcrumb.scss */
.c-breadcrumb a {
  color: #2c5b96;
}

/* line 30, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-breadcrumb.scss */
.c-breadcrumb a.current {
  color: #131313;
  opacity: 0.6;
  text-decoration: none;
}

/* line 1, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-phone-banner.scss */
.c-phone-banner {
  background: #f78b38;
  margin: 16px;
  padding: 5px 16px;
  text-align: center;
  display: none;
}

@media (max-width: 992px) {
  /* line 1, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-phone-banner.scss */
  .c-phone-banner {
    display: block;
  }
}

/* line 12, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-phone-banner.scss */
.c-phone-banner a {
  color: white;
  text-decoration: none;
}

/* line 1, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-mobile-menu.scss */
.c-mobile-menu {
  opacity: 0;
  pointer-events: none;
  background: #131313;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 99999;
  top: 0px;
  left: 0px;
  border: 16px solid white;
  transition: all 300ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* line 19, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-mobile-menu.scss */
.c-mobile-menu ul {
  padding: 0;
  list-style: none;
}

/* line 25, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-mobile-menu.scss */
.c-mobile-menu.active {
  opacity: 1;
  pointer-events: auto;
}

/* line 30, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-mobile-menu.scss */
.c-mobile-menu__logo {
  height: 100px;
  margin-bottom: 1em;
}

/* line 35, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-mobile-menu.scss */
.c-mobile-menu__link {
  color: white;
  text-decoration: none;
  font-size: 1.8rem;
  padding: 0.3em;
  display: block;
}

@media (max-width: 767px) {
  /* line 35, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-mobile-menu.scss */
  .c-mobile-menu__link {
    font-size: 1.2rem;
  }
}

/* line 1, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-certifications.scss */
.c-certifications {
  display: flex;
  align-items: center;
}

/* line 4, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-certifications.scss */
.c-certifications .l-6 {
  font-size: 0;
  margin-top: 0;
}

@media (max-width: 767px) {
  /* line 4, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-certifications.scss */
  .c-certifications .l-6 {
    padding: 0;
  }
}

@media (max-width: 992px) {
  /* line 1, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-certifications.scss */
  .c-certifications {
    flex-direction: column-reverse;
  }
}

/* line 17, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-certifications.scss */
.c-certifications__images img {
  width: calc(33.33% - 12px);
  margin-right: 18px;
}

/* line 20, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-certifications.scss */
.c-certifications__images img:last-of-type {
  margin-right: 0;
}

@media (max-width: 767px) {
  /* line 17, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-certifications.scss */
  .c-certifications__images img {
    width: 33.33%;
    margin-right: 0;
  }
}

/* line 30, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-certifications.scss */
.c-certifications__text {
  padding: 1rem 3rem;
}

/* line 33, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-certifications.scss */
.c-certifications__text h2 {
  font-size: 1.8rem;
}

@media (max-width: 767px) {
  /* line 33, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-certifications.scss */
  .c-certifications__text h2 {
    font-size: 1.4rem;
  }
}

@media (max-width: 992px) {
  /* line 30, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-certifications.scss */
  .c-certifications__text {
    padding: 2rem 3rem;
  }
}

@media (max-width: 767px) {
  /* line 30, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-certifications.scss */
  .c-certifications__text {
    padding: 1rem;
  }
}

/* line 47, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-certifications.scss */
.c-certifications__text * {
  font-size: 1.4rem;
  color: #131313;
}

@media (max-width: 767px) {
  /* line 47, public/wp-content/themes/groupeefficace/assets/src/scss/component/_c-certifications.scss */
  .c-certifications__text * {
    font-size: 1.2rem;
  }
}
