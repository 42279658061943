.c-certifications {
    display: flex;
    align-items: center;
    .l-6 {
        font-size: 0;
        margin-top: 0;
        @include mq('small') {
            padding: 0;
        }
    }
    @include mq('medium') {
        flex-direction: column-reverse;
    }
}

.c-certifications__images {
    img {
        width: calc(33.33% - 12px);
        margin-right: 18px;
        &:last-of-type {
            margin-right: 0;
        }
        @include mq('small') {
            width: 33.33%;
            margin-right: 0;
        }
    }
}

.c-certifications__text {
    padding: 1rem 3rem;

    h2 {
        font-size: 1.8rem;
        @include mq('small') {
            font-size: 1.4rem;
        }
    }
    @include mq('medium') {
        padding: 2rem 3rem;
    }
    @include mq('small') {
        padding: 1rem;
    }
}

.c-certifications__text * {
    font-size: 1.4rem;
    color: $black;
    @include mq('small') {
        font-size: 1.2rem;
    }
}