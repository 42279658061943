.c-footer {
    background: $black;
    margin: 0 $gutter $gutter $gutter;

    .l-3, .l-7, .l-2 {
        padding: ($gutter * 3) ($gutter * 2);

        @include mq(medium) {
            padding: $gutter;
        }
    }
}

.c-footer__logo {
    height: 90px;
    padding: 0;

    @include mq(medium) {
        height: 60px;
        margin-top: $gutter;
    }
}

.c-footer__container  {
    display: flex;
    padding: 0 20px;

    @include mq(medium) {
        flex-direction: column;
    }
}

.c-footer__bio {
    color: white;
    display: block;
    max-width: 600px;
    margin: 0.4em 0;
}

.c-footer__nav {
    list-style: none;
    margin: 0px;

    @include mq(medium) {
        padding: 0;
    }

    a:not(.c-button) {
        color: white;
        opacity: 1;
        padding: 0.3em 0;
        display: inline-block;
        text-decoration: none;
        position: relative;
        &:before {
            content: "";
            height: 1px;
            width: 0%;
            background: $secondary-color;
            position: absolute;
            bottom: 0;
            left: 0px;
            transition: all 300ms ease;
        }
        &:hover {
            &:before {
                width: 100%;
            }
        }
    }

    .c-button--blue {
        display: inline-block;
        margin-bottom: 1em;
        font-size: 16px;
    }
}