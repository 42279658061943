.c-button {
    cursor: pointer;
    background: $secondary-color;
    color: white;
    text-decoration: none;
    font-size: 1rem;
    padding: 0.6em 2em;
    border-radius: 100px;
    border-top-right-radius: 0px;
    outline: none;
    transition: all 200ms ease;
    &:hover {
        background: darken($secondary-color, 10);
    }
}

.c-button--blue {
    @extend .c-button;
    background: $main-color;
    &:hover {
        background: darken($main-color, 10);
    }
}