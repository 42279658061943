.c-breadcrumb {
    margin: $gutter;

    li {
        display: inline-block;
        padding: 0
    }

    ul {
        @include mq(small) {
            padding: 0;
        }
    }

    li:not(:first-of-type) a {
        padding-left: 30px;
        position: relative;
        &:before {
            content: "-";
            position: absolute;
            top: 0px;
            left: 0px;
            padding: 0px 0.5em;

        }
    }

    a {
        color: $main-color;
        &.current {
            color: $black;
            opacity: 0.6;
            text-decoration: none;
        }
    }
}