.c-copyright {
    background-color: $secondary-color;
    margin: 0 $gutter $gutter;

    p {
        margin: 0;
        padding: 1em;
        color: white;
        text-align: center;
        font-size: 0.8rem;
    }
}