/* =======================================================================
Responsive
========================================================================== */
$breakpoints: (
	'xsmall': 		(max-width: 479px),
	'small': 		(max-width: 767px),
	'medium': 		(max-width: 992px),
	'large': 		(max-width: 1199px),
	'xxl': 			(max-width: 1439px)
);


/* =======================================================================
Font-face
========================================================================== */

// @font-face {
// 	font-family: 'font-name-here';
// 	src: url('../fonts/font-name-here/font-name-here.ttf') format("truetype");
// }


/* =======================================================================
Variables
========================================================================== */
// Gutter
$gutter:                 16px;

// Colors
$main-color: 			  #2c5b96;
$secondary-color:         #f78b38;
$black:                   #131313;
$light-gray:              #eaeaea;

// Fonts stacks
$font-stack:              Roboto, Arial, Verdana, sans-serif;
$font-stack-alt:          Georgia, serif;
$font-size-base:          18px;
$line-height-base:        1.4;

// Typography
$color-text:              #333;
$color-link:              $main-color;
$color-link-visited:      lighten($color-link, 20%);
$color-link-hover:        darken($color-link, 20%);
$color-link-active:       darken($color-link, 40%);
