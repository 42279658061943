.c-services-list {
    margin: ( -1 * $gutter) $gutter $gutter $gutter;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    padding: 3rem $gutter 3rem $gutter;
    background: rgba($main-color, 0.1);

    @include mq(medium) {
        padding: $gutter;
    }

    @include mq(small) {
        padding: 0;
        background: transparent;
    }
}

.c-services-list__item {
    width: 400px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background-color: white;
    padding: 1.6em;
    margin: 0 10px 1em 10px;
    color: $main-color;
    border-radius: 0;
    overflow: hidden;
    position: relative;
    transform: scale(1);
    box-shadow: 0 0 10px rgba(black, 0.05);
    text-decoration: none;
    background-size: cover;
    transition: all 350ms ease;

    @include mq(medium) {
        width: calc(50% - 20px);
        min-height: 200px;
    }

    @include mq(small) {
        width: 100%;
        margin: 15px 0 0 0;
        min-height: 200px;
    }

    h3 {
        font-size: 1.4rem;
        position: relative;
        color: white;
        z-index: 2;
    }

    p {
        margin-top: 0;
        font-size: 1rem;
        position: relative;
        color: white;
        z-index: 2;
    }

    span, button {
        position: relative;
        z-index: 2;
        border: none;
    }

    &:before {
        content: url(../images/svg/logo-icon.svg);
        opacity: 0.1;
        position: absolute;
        z-index: 1;
        top: -120px;
        left: 50%;
        transform: translateX(-50%) scale(1.4);
    }

    &:hover {
        z-index: 2;
        transform: scale(1.03);
        box-shadow: 0 0 100px rgba(black, 0.1);
    }
}

.c-services-list__item:after {
	content: "";
	height: 100%;
	width: 100%;
	background: linear-gradient(
		-45deg,
		rgba(darken($main-color, 12), .7),
		rgba(darken($main-color, 12), .9)
	);
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;
}