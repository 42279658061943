/* =======================================================================
Layout
========================================================================== */

.l-container {
	width: 100%;
	max-width: 1660px;
	margin: 0 auto;
}

.l-row {
	clear: both;
	display: block;
}

.l-row--flex {
	display: flex;
	justify-content: space-between;

	@include mq(medium) {
		flex-direction: column;
	}
}

.l-8 {
	width: ((100% / 12) * 8);

	@include mq(medium) {
		width: 100%;
	}
}

.l-7 {
	width: ((100% / 12) * 7);

	@include mq(medium) {
		width: 100%;
	}
}

.l-6 {
	width: 50%;
	margin: $gutter;

	&:nth-of-type(2n + 1) {
		margin-right: ($gutter/2);

		@include mq(medium) {
			margin-right: $gutter;
		}
	}
	&:nth-of-type(2n) {
		margin-left: ($gutter/2);
		@include mq(medium) {
			margin-top: 0;
			margin-left: $gutter;
		}
	}

	@include mq(medium) {
		width: calc(100% - 32px);
	}
}

.l-4 {
	width: 25%;

	@include mq(medium) {
		width: 100%;
	}
}

.l-3 {
	width: (100% / 3);

	@include mq(medium) {
		width: 100%;
	}
}