.c-mobile-menu {
    opacity: 0;
    pointer-events: none;
    background: $black;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 99999;
    top: 0px;
    left: 0px;
    border: $gutter solid white;
    transition: all 300ms ease;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    text-align: center;

    ul {
        padding: 0;
        list-style: none;
    }
}

.c-mobile-menu.active {
    opacity: 1;
    pointer-events: auto;
}

.c-mobile-menu__logo {
    height: 100px;
    margin-bottom: 1em;
}

.c-mobile-menu__link {
    color: white;
    text-decoration: none;
    font-size: 1.8rem;
    padding: 0.3em;
    display: block;

    @include mq(small) {
        font-size: 1.2rem;
    }
}