.c-header {
    box-shadow: 0 0 10px rgba($black, .3);
    background: $black;
    margin: $gutter;
}

.c-header__logo {
    height: 120px;
    padding: 1.4em 1em;
}

.c-header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.c-header__nav {
    display: flex;
    flex-direction: row;
    list-style: none;
}

.c-header__link {
    padding: .6em 1em;
    color: white;
    text-decoration: none;
    font-size: 1rem;
    position: relative;
    &:before {
        content: "";
        height: 1px;
        width: 0%;
        background: white;
        position: absolute;
        bottom: 0;
        left: 10px;
        transition: all 300ms ease;
    }
    &:hover {
        &:before {
            width: calc(100% - 20px);
        }
    }
}

.c-header__hamburger {
    height: 40px;
    display: block
}
