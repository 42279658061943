.h-hide-tablet {
    @include mq(medium) {
        display: none;
    }
}

.h-show-tablet {
    display: none;
    @include mq(medium) {
        display: inline-block;
    }
}

.h-margin-top-15 {
    margin-top: 15px !important;
}