.c-phone-banner {
    background: $secondary-color;
    margin: $gutter;
    padding: 5px $gutter;
    text-align: center;
    display: none;

    @include mq(medium) {
        display: block;
    }

    a {
        color: white;
        text-decoration: none;
    }
}