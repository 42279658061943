/* =======================================================================
c-category-box
========================================================================== */

.c-category-box {
	height: 500px;
	background-size: cover;
	background-position: 50% 30%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	@include mq(medium) {
		height: 400px;
	}
}


.c-category-box:before {
	content: "";
	height: 100%;
	width: 100%;
	background: linear-gradient(
		-45deg,
		rgba(darken($black,12), .5),
		rgba(darken($black,12), .8)
	);
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;
}

.c-category-box__content {
	position: relative;
	z-index: 2;
	color: white;
	width: 100%;
	padding: 3rem;

	@include mq(small) {
        padding: 1rem 30px;
    }


	h2 {
        font-size: 3rem;
        margin-top: 0px;
		margin-bottom: 0.5em;

		@include mq(medium) {
			font-size: 2.4rem;
		}

		@include mq(small) {
			font-size: 1.8rem;
		}
	}

	p {
		margin-bottom: 0;
	}
}

.c-category-box__title {
	text-align: center;
}