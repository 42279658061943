html,
button,
input,
select,
textarea {
	color: $color-text;
	font-family: $font-stack;
}

body,
html {
	padding: 0;
	margin: 0;
	font-size: $font-size-base;
	line-height: $line-height-base;
}

* {
	box-sizing: border-box;
}

img { vertical-align: middle; max-width: 100%; }

h2 {
	line-height: 1.2em;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

fieldset {
	border: 0;
	margin: 0; padding: 0;
}

textarea {
	resize: vertical;
}

// Selection
::-moz-selection {
	background: black;
	color: white;
	text-shadow: none;
}

::selection {
	background: black;
	color: white;
	text-shadow: none;
}

// Placeholder color
::-webkit-input-placeholder { /* WebKit browsers */
	color: white;
	opacity: 0.5;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	color: white;
	opacity: 0.5;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
	color: white;
	opacity: 0.5;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
	color: white;
	opacity: 0.5;
}
