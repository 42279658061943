/* =======================================================================
Forms
========================================================================== */

input, textarea {
    height: 40px;
    border-radius: 100px;
    border: 1px solid rgba(white, 1);
    background: transparent;
    color: white;
    padding: 1.2em 1.4em;
    margin: 0.5em 0;
    font-size: 1rem;
    outline: none;
    width: 100%;
    max-width: 800px;
}

textarea {
    height: 200px;
    resize: vertical;
    border-radius: 24px;
}

span.wpcf7-not-valid-tip {
    margin-bottom: 0px;
    color: $secondary-color;
}

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
    color: $secondary-color;
    border: none;
    padding: 0;
}

div.wpcf7-mail-sent-ok {
    color: white;
    border: none;
    padding: 0;
}