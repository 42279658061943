/* =======================================================================
c-banner
========================================================================== */

.c-banner {
	height: 700px;
	margin: 0px $gutter;
	background-size: cover;
	background-position: 50% 30%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	@include mq(small) {
		height: auto;
		padding: 30px 0 60px 0;
    }
}

.c-banner--category {
	@extend .c-banner;
	height: auto;
	padding: 100px 0 150px 0;
	margin-bottom: $gutter;
	&:before {
		background: linear-gradient(
			-45deg,
			rgba(darken($main-color,12), .3),
			rgba(darken($main-color,12), .6)
		);
	}
	@include mq('small') {
		padding: 50px 0 100px 0;
	}
}

.c-banner:before {
	content: "";
	height: 100%;
	width: 100%;
	background: linear-gradient(
		-45deg,
		rgba(darken($main-color,12), .5),
		rgba(darken($main-color,12), .8)
	);
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;
}

.c-banner__content {
	position: relative;
	z-index: 2;
	color: white;
	width: 100%;
	padding: 3rem;

	@include mq(small) {
        padding: 1rem 30px;
    }


	h2 {
		font-size: 3rem;
		margin-bottom: 0px;

		@include mq(medium) {
			font-size: 2.4rem;
		}

		@include mq(small) {
			font-size: 1.8rem;
		}
	}

	p {
		margin-bottom: 2em;
		display: block;
		max-width: 900px;
	}

	a:not(.c-button) {
		color: $secondary-color;
		text-decoration: none;
	}
}

.c-banner__title {
	text-align: center;
}

.c-banner__logo {
	position: absolute;
    height: 430px;
    top: 50%;
    right: 10%;
	transform: translateY(-50%);

	@include mq(medium) {
		height: 200px;
		opacity: 0.5;
		right: $gutter;
    }
}

